import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";

const API_ROOT = "https://api.acaflow.app/v1/process";

function processFile(file) {
  const formData = new FormData();
  formData.append("file", file);
  return fetch(API_ROOT, {
    method: "POST",
    body: formData,
  }).then((res) => res.json());
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 10,
  borderColor: "#aaa",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#444",
  outline: "none",
  transition: "border .24s ease-in-out",
  textAlign: "center",
  height: "50px"
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function Dropzone(props) {
  let navigate = useNavigate();
  let [hasError, setHasError] = useState(null);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: { "application/pdf": [] } });

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return;
    }

    setHasError(null);

    const file = acceptedFiles[0];

    processFile(file)
      .then((res) => {
        navigate(`/${res.md5}`);
      })
      .catch((err) => {
        alert(err.toString());
        // TODO: show error
      });
  }, [acceptedFiles, navigate]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        {acceptedFiles.length === 0 ? (
          <div>
            <input {...getInputProps()} />
            <p>Drag 'n' drop a paper here, or click to select files</p>
          </div>
        ) : (
          <div style={{padding: "20px"}}>
            <PropagateLoader size={10} color={"#666"} loading={true} />
          </div>
        )}
      </div>
      {hasError}
    </div>
  );
}
