

export class TeiParser {
  constructor(tei) {
    this.parser = new DOMParser();
    this.xmlDoc = this.parser.parseFromString(tei, "text/xml");
  }

  get title() {
    return this.xmlDoc.querySelector("teiHeader title[type=main]").textContent;
  }

  get authors() {
    return Array.from(
      this.xmlDoc.querySelectorAll("teiHeader fileDesc author")
    ).map((author) => {
      const forenameEl = author.querySelector("forename");
      const forename = forenameEl ? forenameEl.textContent : "";

      const surnameEl = author.querySelector("surname");
      const surname = surnameEl ? surnameEl.textContent : "";

      return {
        forename,
        surname
      };
    }).filter((author) => author.forename !== "" && author.surname !== "");
  }

  get abstract() {
    return this.xmlDoc.querySelector("teiHeader profileDesc abstract div");
  }

  get body() {
    return this.xmlDoc.querySelector("text body");
  }

  get references() {
    return Array.from(
      this.xmlDoc.querySelectorAll("back listBibl biblStruct")
    ).map((reference) => {

      let published = reference.querySelector("date[type=\"published\"");
      published = published ? published.attributes.getNamedItem("when").textContent : "";

      let journal = reference.querySelector("monogr title");
      journal = journal ? journal.textContent : "";
      
      return {
        id: reference.attributes['xml:id'].value,
        title: reference.querySelector("title").textContent,

        journal,
        published,


        authors: Array.from(
          reference.querySelectorAll("analytic author")
        ).map((author) => {
          let forenameEl = author.querySelector("forename");
          let forename = forenameEl ? forenameEl.textContent : "";

          let surnameEl = author.querySelector("surname");
          let surname = surnameEl ? surnameEl.textContent : "";

          return {
            forename,
            surname,
          };
        }
        ),
      };
    });
  }
}
