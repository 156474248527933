import { useEffect } from "react";
import Dropzone from "./Dropzone";

export default function Home() {
  useEffect(() => {
    document.title = "Acaflow";
  })

  return (
    <div className="App" style={{
      height: "100vh",
    }}>
      <div className="container" style={{
        boxSizing: "border-box",
        padding: "0 20px",
      }}>
        <h1 style={{margin: 0, padding: "20px 0"}}>Acaflow</h1>
      <Dropzone />

      </div>
    </div>
  );
}
