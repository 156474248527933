import "./App.css";
import { FiHome, FiSun } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TeiParser } from "./lib/tei-parser";

class Renderer {
  constructor(md5Hash) {
    this.md5Hash = md5Hash;
    this.graphicIndex = 0;
  }

  renderContent(root, graphicIndex = 0) {
    if (root === null || root === undefined) {
      return null;
    }

    if (typeof root.data === "string") {
      return <>{root.data}</>;
    }

    if (root.tagName === "div" || root.tagName === "body") {
      return (
        <div>
          {Array.from(root.children).map((child) => {
            return this.renderContent(child, false, graphicIndex);
          })}
        </div>
      );
    }

    if (root.tagName === "ref") {
      window.bobx = root;

      if (root.attributes["target"] === undefined) {
        return <>{this.renderContent(root.children[0], graphicIndex)}</>;
      }

      return <a href={root.attributes.target.value}>{root.innerHTML}</a>;
    }

    if (root.tagName === "formula") {
      return (
        <div
          style={{
            textAlign: "center",
            fontSize: "large",
            padding: "1em",
          }}
        >
          {root.textContent}
        </div>
      );
    }

    if (root.tagName === "p") {
      return (
        <p>
          {Array.from(root.children).map((child) => {
            return this.renderContent(child, false, graphicIndex);
          })}
        </p>
      );
    }

    if (root.tagName === "s") {
      return (
        <span>
          {Array.from(root.childNodes).map((child) => {
            return <>{this.renderContent(child, false, graphicIndex)} </>;
          })}
        </span>
      );
    }

    if (root.tagName === "head") {
      return <h2>{root.innerHTML}</h2>;
    }

    if (root.tagName === "note") {
      // TODO: render notes
      return "";
    }

    if (root.tagName === "figure") {
      return (
        <div className="figure">
          {Array.from(root.children).map((child) => {
            return this.renderContent(child, false, graphicIndex);
          })}
        </div>
      );
    }

    if (root.tagName === "graphic") {
      this.graphicIndex += 1;
      return (
        <img
          src={`https://api.acaflow.app/v1/cache/${this.md5Hash}/figures/${this.graphicIndex}.png`}
          alt=""
        />
      );
    }

    if (root.tagName === "table") {
      return (
        <table>
          {Array.from(root.children).map((child) => {
            return this.renderContent(child, false, graphicIndex);
          })}
        </table>
      );
    }

    if (root.tagName === "row") {
      return (
        <tr>
          {Array.from(root.children).map((child) => {
            return this.renderContent(child, false, graphicIndex);
          })}
        </tr>
      );
    }

    if (root.tagName === "cell") {
      console.log(root);
      return <td>{root.textContent}</td>;
    }

    console.warn("Unknown tag: " + root.tagName);
  }
}

function Reader() {
  const { md5 } = useParams();
  const [theme, setTheme] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [tei, setTei] = useState(null);

  useEffect(() => {
    fetch(`https://api.acaflow.app/v1/cache/${md5}`)
      .then((response) => response.text())
      .then((rawTei) => {
        const parser = new TeiParser(rawTei);

        setTei(parser);
        setIsLoading(false);

        document.title = parser.title;
      });
  }, [md5]);

  if (isLoading) {
    return (
      <div className="App">
        <header>Loading</header>
      </div>
    );
  }

  const renderer = new Renderer(md5);

  return (
    <div className={"App App-theme-" + theme}>
      <header>
        <nav>
          <ul>
            <li>
              <Link to={"/"}>
                <FiHome />
              </Link>
            </li>
            <li>
              <a
                href="#theme"
                onClick={() => {
                  if (theme === "light") {
                    setTheme("dark");
                  } else {
                    setTheme("light");
                  }
                }}
              >
                <FiSun />
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="Reader">
        <div className="Reader-body">
          <h1>{tei.title}</h1>

          <div className="Reader-authors">
            {tei.authors.map((author, index) => {
              return (
                <>
                  <span className="Reader-author" key={author}>
                    {author.forename} {author.surname}
                  </span>
                  {index < tei.authors.length - 1 ? ", " : null}
                </>
              );
            })}
          </div>

          <div className="Reader-abstract">
            {renderer.renderContent(tei.abstract)}
          </div>

          {renderer.renderContent(tei.body)}

          <div className="Reader-references">
            <h2 id="references">References</h2>
            <ol>
              {tei.references.map((reference) => {
                return (
                  <li key={reference.id} id={reference.id}>
                    <a
                      href={
                        "https://scholar.google.com/scholar?q=" +
                        encodeURIComponent(reference.title)
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {reference.title}
                    </a>
                    {reference.authors.length > 0 ? <br /> : null}
                    <span
                      style={{
                        color: "gray",
                        fontSize: "smaller",
                      }}
                    >
                      {reference.authors.map((author, index) => {
                        return (
                          <>
                            {author.forename} {author.surname}
                            {index < reference.authors.length - 1 ? ", " : null}
                          </>
                        );
                      })}
                    </span>
                    <br />
                    <span
                      style={{
                        color: "gray",
                        fontSize: "smaller",
                      }}
                    >
                      {reference.journal} ({reference.published})
                    </span>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reader;
